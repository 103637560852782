import { HStack, Text } from "@chakra-ui/react";
import { TicketStatus } from "../types";
import { ArrowUpFromDot, ArrowDownToDot, Check, Loader } from "lucide-react";

const ticketStatusText = {
  [TicketStatus.Unknown]: "Pending",
  [TicketStatus.Pending]: "Pending",
  [TicketStatus.WaitingForConfirmBySrc]: "Sending",
  [TicketStatus.WaitingForConfirmByDest]: "Receiving",
  [TicketStatus.Finalized]: "Finalized",
};

const ticketStatusIcon = {
  [TicketStatus.Unknown]: Loader,
  [TicketStatus.Pending]: Loader,
  [TicketStatus.WaitingForConfirmBySrc]: ArrowUpFromDot,
  [TicketStatus.WaitingForConfirmByDest]: ArrowDownToDot,
  [TicketStatus.Finalized]: Check,
};

const ticketStatusColor = {
  [TicketStatus.Unknown]: "pink.400",
  [TicketStatus.Pending]: "pink.400",
  [TicketStatus.WaitingForConfirmBySrc]: "orange.400",
  [TicketStatus.WaitingForConfirmByDest]: "teal.400",
  [TicketStatus.Finalized]: "green.400",
};

export default function TicketStatusComp({ status }: { status: TicketStatus }) {
  const Icon = ticketStatusIcon[status];
  return (
    <HStack>
      <HStack
        bg={ticketStatusColor[status]}
        px={2}
        py={1}
        borderRadius="full"
        gap={1}
      >
        <Icon size={18} color="black" />
        <Text color="black" fontWeight={600} className="number">
          {ticketStatusText[status]}
        </Text>
      </HStack>
    </HStack>
  );
}
