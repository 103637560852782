import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  chakra,
  Box,
} from "@chakra-ui/react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@nivo/core";
import { BarChartHorizontalBig } from "lucide-react";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useMemo, useState } from "react";
import { ChainID } from "@omnity/widget/src/types";
import { TokenMeta } from "src/types";
import { getChainName } from "src/utils/chains";

const BarChartHorizontalBigIcon = chakra(BarChartHorizontalBig);

async function fetchTicketsOnL2s(
  token_id: string,
  chain_id: string,
): Promise<number> {
  try {
    const doc = gql`
      {
        ticket_aggregate(
          where: {
            _or: [
              { dst_chain: { _eq: "${chain_id}" } }
              { src_chain: { _eq: "${chain_id}" } }
            ]
            token: { _eq: "${token_id}" }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return data.ticket_aggregate.aggregate.count;
  } catch (error) {
    return 0;
  }
}

export default function TicketsOfRunesBar({ rune }: { rune?: TokenMeta }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ticketCounts, setTicketCounts] = useState<
    { count: number; chainId: ChainID }[]
  >([]);
  const theme = useTheme();

  useEffect(() => {
    if (rune?.token_id) {
      const chainIds = rune.dst_chains.filter(
        (c) => c !== ChainID.Bitcoin,
      ) as ChainID[];
      Promise.all(
        chainIds.map((chain_id) => fetchTicketsOnL2s(rune.token_id, chain_id)),
      ).then((counts) => {
        setTicketCounts(
          chainIds
            .map((chainId, idx) => ({
              chainId,
              count: counts[idx] ?? 0,
            }))
            .sort((a, b) => a.count - b.count),
        );
      });
    }
  }, [rune?.token_id]);

  const _data = useMemo(() => {
    return ticketCounts.map((d) => ({
      chain: getChainName(d.chainId) ?? "",
      ticket: d.count,
      ticketColor: "hsl(238, 70%, 50%)",
    }));
  }, [ticketCounts.length]);

  if (!rune) {
    return null;
  }

  const _theme = Object.assign(theme, {
    labels: {
      ...theme.labels,
      text: {
        ...theme.labels.text,
        fill: "#999",
      },
    },
    axis: {
      ...theme.axis,
      ticks: {
        ...theme.axis.ticks,
        line: {
          ...theme.axis.ticks.line,
          stroke: "#fff",
        },
        text: {
          ...theme.axis.ticks.text,
          fill: "#fff",
          fontSize: 18,
        },
      },
    },
    text: {
      ...theme.text,
      fill: "#fff",
      fontSize: 12,
    },
    annotations: {
      ...theme.annotations,
      text: {
        ...theme.annotations.text,
        fill: "#fff",
        fontSize: 12,
      },
    },
  });

  return (
    <>
      <BarChartHorizontalBigIcon
        size={26}
        color="blue.400"
        cursor="pointer"
        onClick={onOpen}
      />
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="#000433">
          <ModalHeader>Tickets on L2s</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              w={450}
              h={400}
              color="black"
              fontSize={18}
              fontFamily="Reddit Mono"
            >
              <ResponsiveBar
                data={_data}
                theme={_theme}
                keys={["ticket"]}
                indexBy="chain"
                margin={{ top: 0, right: 70, bottom: 10, left: 100 }}
                padding={0.3}
                groupMode="stacked"
                layout="horizontal"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={{ scheme: "category10" }}
                defs={[]}
                fill={[
                  {
                    match: {
                      id: "count",
                    },
                    id: "lines",
                  },
                ]}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisLeft={{
                  tickSize: 0,
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                labelTextColor="#999"
                enableLabel={false}
                enableTotals
                enableGridY={false}
                ariaLabel="Tickets on L2"
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
