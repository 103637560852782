import {
  chakra,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  useDisclosure,
  VStack,
  Avatar,
  useOutsideClick,
  Input,
} from "@chakra-ui/react";
import { useMetadata } from "src/contexts/metadata";
import { ChevronDown, XCircle } from "lucide-react";
import { beautifyTokenId } from "src/utils/format";
import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

const ChevronDownIcon = chakra(ChevronDown);
const XCircleIcon = chakra(XCircle);

export default function TicketFilterTokenId({
  tokenId,
  setTokenId,
  tokenIds = [],
}: {
  tokenId?: string;
  setTokenId: (chainId: string) => void;
  tokenIds?: string[];
}) {
  const [keyword, setKeyword] = useState("");
  const ref = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { tokens } = useMetadata();
  const selectedRune = tokens.find((rune) => rune.token_id === tokenId);
  useOutsideClick({
    ref,
    handler: onClose,
  });
  const [_, setSearchParams] = useSearchParams();
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <HStack>
            <HStack
              borderWidth={0.5}
              borderColor="gray.500"
              _hover={{ bg: "gray.600" }}
              px={4}
              py={2}
              cursor="pointer"
              bg={selectedRune ? "gray.600" : "transparent"}
              onClick={onToggle}
            >
              {selectedRune ? (
                <HStack
                  key={selectedRune.token_id}
                  cursor="pointer"
                  w="100%"
                  onClick={() => setTokenId(selectedRune.token_id)}
                >
                  <Avatar
                    w="24px"
                    h="24px"
                    src={selectedRune.icon}
                    name={selectedRune.symbol}
                  />
                  <Text>{beautifyTokenId(selectedRune.token_id)}</Text>
                </HStack>
              ) : (
                <Text>Token</Text>
              )}
              <ChevronDownIcon size={24} />
            </HStack>
            <XCircleIcon
              size={20}
              color={selectedRune ? "blue.500" : "transparent"}
              cursor="pointer"
              onClick={() => {
                selectedRune && setTokenId("");
                setSearchParams({ page: "1" });
              }}
            />
          </HStack>
        </PopoverTrigger>
        <PopoverContent w={300} p={0} ref={ref}>
          <PopoverBody p={0}>
            <Input
              placeholder="Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <VStack
              alignItems="flex-start"
              w="100%"
              gap={0}
              maxH={300}
              overflowY="scroll"
            >
              {tokens
                .filter((rune) => {
                  if (tokenIds.length === 0) {
                    return true;
                  }
                  return tokenIds.includes(rune.token_id);
                })
                .filter((rune) => {
                  if (keyword.trim()) {
                    return rune.name
                      .toLowerCase()
                      .includes(keyword.trim().toLowerCase());
                  }
                  return true;
                })
                .map((rune) => {
                  return (
                    <HStack
                      key={rune.token_id}
                      cursor="pointer"
                      w="100%"
                      pl={4}
                      py={2}
                      bg={
                        tokenId === rune.token_id ? "gray.600" : "transparent"
                      }
                      _hover={{ bg: "gray.600" }}
                      onClick={() => {
                        setTokenId(
                          tokenId === rune.token_id ? "" : rune.token_id,
                        );
                        setSearchParams({ page: "1" });
                        onClose();
                      }}
                    >
                      <Avatar
                        w="24px"
                        h="24px"
                        src={rune.icon}
                        name={rune.symbol}
                      />
                      <Text>{beautifyTokenId(rune.token_id)}</Text>
                    </HStack>
                  );
                })}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
