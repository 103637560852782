import { ChainID } from "@omnity/widget/src/types";

export enum TicketType {
  Normal = "Normal",
  Resubmit = "Resubmit",
}

export enum TicketStatus {
  Unknown = "Unknown",
  WaitingForConfirmBySrc = "WaitingForConfirmBySrc",
  WaitingForConfirmByDest = "WaitingForConfirmByDest",
  Finalized = "Finalized",
  Pending = "Pending",
}

export enum TicketAction {
  Mint = "Mint",
  Burn = "Burn",
  Transfer = "Transfer",
  Redeem = "Redeem",
  RedeemIcpChainKeyAssets = "RedeemIcpChainKeyAssets",
}

export interface TicketItem {
  action: TicketAction;
  amount: string;
  dst_chain: string;
  memo: string;
  receiver: string;
  sender: string;
  src_chain: string;
  status: TicketStatus;
  ticket_id: string;
  ticket_seq: number;
  ticket_time: number;
  ticket_type: TicketType;
  token: string;
  tx_hash?: string;
  intermediate_tx_hash?: string;
}

export enum TxHashType {
  Src,
  Intermediate,
  Dst,
}

export interface AmountOnL2 {
  chain_id: ChainID;
  amount: string;
}

export interface TokenMeta {
  decimals: number;
  dst_chains: string[];
  icon: string;
  issue_chain: string;
  metadata: {
    rune_id?: string;
    ledger_id?: string;
  };
  name: string;
  symbol: string;
  token_id: string;
  amount_on_l2?: AmountOnL2[];
  volumeAmount?: bigint;
  price?: string;
  token_ledger_id_on_chains: {
    contract_id: string;
    chain_id: ChainID;
  }[];
}
