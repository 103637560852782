import { Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function KeyValuePair({
  width,
  item,
  valueLineheight,
  isNumber,
  fontSize = 20,
  alignItems = "flex-start",
  horizontal = false,
}: {
  width?: string | number;
  item: {
    key: string;
    value?: string | number | React.ReactNode;
  };
  isNumber?: boolean;
  valueLineheight?: number;
  fontSize?: number;
  alignItems?: string;
  horizontal?: boolean;
}) {
  return (
    <Stack
      flexDir={{ base: "column", md: horizontal ? "row" : "column" }}
      gap={0}
      py={2}
      px={0}
      w={horizontal ? "100%" : width}
      alignItems={{
        base: "flex-start",
        md: horizontal ? "center" : alignItems,
      }}
    >
      <Text
        color="gray.400"
        fontSize={16}
        w={horizontal ? 180 : undefined}
        textAlign="left"
      >
        {item.key}
      </Text>

      {["string", "number"].includes(typeof item.value) ? (
        <Text
          color="gray.100"
          fontSize={fontSize}
          fontWeight={600}
          lineHeight={valueLineheight}
          className={isNumber ? "number" : ""}
        >
          {item.value}
        </Text>
      ) : (
        item.value
      )}
    </Stack>
  );
}
