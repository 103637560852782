import {
  Box,
  Circle,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  Divider,
  Spinner,
  Badge,
  AvatarGroup,
  Avatar,
} from "@chakra-ui/react";
import { ChainID, ChainType } from "@omnity/widget/src/types";
import { useMetadata } from "src/contexts/metadata";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useState } from "react";
import _ from "lodash";
import { MD_WIDTH } from "src/utils/constants";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import useVolume from "src/hooks/useVolume";
import { readableNumber } from "@omnity/widget/src/utils/format";
import IChainLogo from "src/components/IChainLogo";
import { getAssetTypes, getChainName, uniqChains } from "src/utils/chains";

async function fetchTicketCountByChainId(chainId: ChainID): Promise<number> {
  try {
    const doc = gql`
      {
        ticket_aggregate(order_by: {ticket_time: desc}, where: {_or: [{src_chain:{_eq:"${chainId}"}}, {dst_chain:{_eq:"${chainId}"}}]}) {
          aggregate {
            count
          }
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return data.ticket_aggregate.aggregate.count;
  } catch (error) {
    return 0;
  }
}

export default function Chains() {
  const { chains } = useMetadata();
  const [ticketCounts, setTicketCounts] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all(
      chains.map((chain) =>
        fetchTicketCountByChainId(chain.chain_id as ChainID),
      ),
    ).then((results) => {
      setTicketCounts(results);
      setIsLoading(false);
    });
  }, [chains.length]);

  const navigate = useNavigate();
  const { chainVolumes } = useVolume();

  return (
    <VStack w="100%" alignItems="flex-start" mt={8} gap={0}>
      <Helmet>
        <title>Chain List | Omnity Hub Explorer</title>
      </Helmet>
      <VStack w="100%" gap={1}>
        <Text fontSize={28}>Chain List</Text>
      </VStack>

      <Divider bg="gray.500" w="100%" h={1} mt={4} />

      <Box w={{ base: window.innerWidth, md: "100%" }} overflowX="scroll">
        <TableContainer w={{ base: "100%", md: MD_WIDTH }}>
          <Table size="lg">
            <Thead>
              <Tr>
                <Th>Chain</Th>
                <Th textAlign="right">TVL</Th>
                <Th>Status</Th>
                <Th>Assets</Th>
                <Th>Peers</Th>
                <Th textAlign="right">Tickets</Th>
              </Tr>
            </Thead>
            <Tbody>
              {chains
                .map((chain, idx) => {
                  const volume =
                    chainVolumes?.find((c) => c.chain_id === chain.chain_id)
                      ?.volume ?? 0;
                  return {
                    ...chain,
                    count: ticketCounts[idx] ?? 0,
                    volume,
                  };
                })
                .sort((a, b) => b.volume - a.volume)
                .map((chain) => {
                  const chain_id = chain.chain_id as ChainID;
                  const chain_name = getChainName(chain_id);
                  if (chain.chain_type === ChainType.Settlement) {
                    return null;
                  }
                  const isBitcoin = chain_id === ChainID.Bitcoin;
                  return (
                    <Tr
                      key={chain_id}
                      cursor="pointer"
                      _hover={{ bg: "gray.700" }}
                      onClick={() => navigate(`/chain/${chain_name}`)}
                    >
                      <Td>
                        <HStack>
                          <IChainLogo chain={chain_id} size={32} />
                          <Text fontWeight={600} fontSize={20}>
                            {chain_name}
                          </Text>
                        </HStack>
                      </Td>
                      <Td
                        textAlign="right"
                        fontWeight={600}
                        fontSize={18}
                        className="number"
                      >
                        {chain.volume && !isBitcoin
                          ? `$${readableNumber(Math.ceil(chain.volume), 0)}`
                          : "-"}
                      </Td>
                      <Td>
                        <HStack>
                          <Circle
                            bg={
                              chain.chain_state === "Active"
                                ? "green.500"
                                : "red.500"
                            }
                            size={4}
                          />
                          <Text
                            color={
                              chain.chain_state === "Active"
                                ? "green.500"
                                : "red.500"
                            }
                          >
                            {chain.chain_state}
                          </Text>
                        </HStack>
                      </Td>
                      <Td>
                        <HStack>
                          {getAssetTypes(chain.chain_id as ChainID).map((t) => (
                            <Badge colorScheme="teal" key={t}>
                              {t}
                            </Badge>
                          ))}
                        </HStack>
                      </Td>
                      <Td>
                        <AvatarGroup size="sm" max={5}>
                          {uniqChains(chain.counterparties as ChainID[]).map(
                            (counterparty) => {
                              return (
                                <Avatar
                                  icon={
                                    <IChainLogo
                                      key={counterparty}
                                      chain={counterparty as ChainID}
                                      size={28}
                                    />
                                  }
                                  background="transparent"
                                  key={counterparty}
                                />
                              );
                            },
                          )}
                        </AvatarGroup>
                      </Td>
                      <Td className="number" color="gray.400" textAlign="right">
                        {chain.count}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
        {isLoading && <Spinner size="lg" />}
      </Box>
    </VStack>
  );
}
