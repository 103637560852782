/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useEffect, useMemo } from "react";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { formatUnits, readableNumber } from "@omnity/widget/src/utils/format";
import { ChainID } from "@omnity/widget/src/types";
import { TokenMeta } from "src/types";
import { fetchTokenPrice } from "src/utils/price";

const IGNORE_CHAINS = ["osmo-test-5"];

async function fetchMetadata(): Promise<{
  chains: ChainMeta[];
  tokens: TokenMeta[];
  ticket_count: number;
}> {
  try {
    const doc = gql`
      {
        chain_meta {
          canister_id
          chain_id
          chain_state
          chain_type
          contract_address
          counterparties
          fee_token
        }
        token_meta {
          decimals
          dst_chains
          icon
          issue_chain
          metadata
          name
          symbol
          token_id
          token_ledger_id_on_chains {
            contract_id
            chain_id
          }
        }
        ticket_aggregate {
          aggregate {
            count
          }
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return {
      chains: data.chain_meta.filter(
        (c: any) => !IGNORE_CHAINS.includes(c.chain_id),
      ),
      tokens: data.token_meta,
      ticket_count: data.ticket_aggregate.aggregate.count,
    };
  } catch (error) {
    return {
      chains: [],
      tokens: [],
      ticket_count: 0,
    };
  }
}

async function fetchRunesAmountOnL2(
  token_id: string,
): Promise<{ amount: string; chain_id: ChainID }[]> {
  try {
    const doc = gql`
      {
        token_on_chain(
          where: { token_id: { _eq: "${token_id}" } }
        ) {
          amount
          chain_id
        }
      }
    `;
    const data = await fetchGraph(doc, {});

    return data.token_on_chain;
  } catch (error) {
    return [];
  }
}

export interface ChainMeta {
  canister_id: string;
  chain_id: string;
  chain_state: "Active" | "Deactive";
  chain_type: string;
  contract_address: string;
  counterparties: string[];
  fee_token?: string;
  volume?: bigint;
}

interface MetadataContextProps {
  chains: ChainMeta[];
  tokens: TokenMeta[];
  ticket_count: number;
  priceFetched?: boolean;
  formatTokenAmount?: (
    amount: string | number,
    token_id: string,
    readable?: boolean,
  ) => {
    balance: string;
    symbol: string;
    icon: string;
    price: string;
    digits: number;
  };
}

const initialState: MetadataContextProps = {
  chains: [],
  tokens: [],
  ticket_count: 0,
  priceFetched: false,
};

const MetadataContext = createContext<MetadataContextProps>(initialState);

export function useMetadata() {
  return useContext(MetadataContext);
}

(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

const stateAtom = atomWithStorage<MetadataContextProps>(
  "metadata",
  initialState,
);

export function MetadataProvider({ children }: { children: React.ReactNode }) {
  const [meta, setMeta] = useAtom(stateAtom);

  useEffect(() => {
    fetchMetadata().then(async (res) => {
      setMeta(res);
      const amountOnL2 = await Promise.all(
        res.tokens.map((rune) => fetchRunesAmountOnL2(rune.token_id)),
      );

      const tokens = res.tokens.map((rune, index) => ({
        ...rune,
        amount_on_l2: amountOnL2[index],
        volumeAmount: (amountOnL2[index] ?? []).reduce((acc: bigint, item) => {
          return acc + BigInt(item.amount);
        }, 0n),
      }));
      setMeta({
        ...res,
        tokens,
        priceFetched: false,
      });

      const result = await fetchTokenPrice(tokens);

      setMeta((prev) => ({
        ...prev,
        tokens: result,
        priceFetched: true,
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { chains, tokens, ticket_count, priceFetched } = meta;
  const contextValue = useMemo(() => {
    return {
      chains,
      tokens,
      ticket_count,
      priceFetched,
      formatTokenAmount: (
        amount: string | number,
        token_id: string,
        readable = true,
      ) => {
        const rune = tokens.find((r) => r.token_id === token_id);
        let balance = String(amount);
        let symbol = "";
        let icon = "";
        let digits = rune?.decimals ?? 3;

        if (rune) {
          balance = formatUnits(BigInt(amount), rune.decimals);
          symbol = token_id.split("-")[2] ?? "";
          icon = rune.icon;

          if (rune.price) {
            if (Number(rune.price) > 1000) {
              digits = 5;
            }
            if (Number(rune.price) < 1) {
              digits = 3;
            }
          }

          if (readable) {
            balance = readableNumber(balance, digits);
          }
        }
        return {
          balance,
          symbol,
          icon,
          price: rune?.price ?? "0",
          digits,
        };
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens.length, chains.length, ticket_count, priceFetched]);

  return (
    <MetadataContext.Provider value={contextValue}>
      {children}
    </MetadataContext.Provider>
  );
}
