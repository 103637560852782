import {
  Avatar,
  AvatarGroup,
  Badge,
  Divider,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  useClipboard,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import KeyValuePair from "../components/KeyValuePair";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useState } from "react";
import TicketTable from "../components/TicketTable";
import { TicketAction, TicketItem, TicketStatus } from "../types";
import {
  beautifyAddress,
  getChainByAddress,
  getKeywordType,
} from "src/utils/format";
import _ from "lodash";
import { ChainID } from "@omnity/widget/src/types";
import { CheckCheck, Copy } from "lucide-react";
import TicketFilterChainId from "src/components/TicketFilterChainId";
import TicketFilterTokenId from "src/components/TicketFilterTokenId";
import TicketFilterAction from "src/components/TicketFilterAction";
import TicketFilterStatus from "src/components/TicketFilterStatus";
import { Helmet } from "react-helmet";
import { useMetadata } from "src/contexts/metadata";
import Pagination from "src/components/Pagination";
import { PAGE_SIZE } from "src/utils/constants";
import IChainLogo from "src/components/IChainLogo";
import { formatActionQuery } from "src/utils/helper";

async function fetchTicketsByAddress(
  addr: string,
  page: number = 1,
  chainId?: ChainID,
  action?: TicketAction,
  tokenId?: string,
  status?: TicketStatus,
): Promise<{ tickets: TicketItem[]; total: number }> {
  try {
    const _tokenId = tokenId ? `token: { _eq: "${tokenId}" }` : "";
    const _chainId = chainId
      ? `,{_or: [
              { dst_chain: { _eq: "${chainId}" } }
              { src_chain: { _eq: "${chainId}" } }
            ]}`
      : "";
    const _action = formatActionQuery(action);
    const _status = status ? `status: { _eq: "${status}" }` : "";
    const doc = gql`
      {
        ticket_aggregate(order_by: {ticket_time: desc},
          where: {
            _and: [{_or: [{receiver:{_iregex:"${addr}"}}, {sender:{_iregex:"${addr}"}}]} ${_chainId}]
            ${_tokenId}
            ${_action}
            ${_status}
        }) {
          aggregate {
            count
          }
        }
        ticket(
          order_by: {ticket_time: desc},
          where: {
            _and: [{_or: [{receiver:{_iregex:"${addr}"}}, {sender:{_iregex:"${addr}"}}]} ${_chainId}]
            ${_tokenId}
            ${_action}
            ${_status}
          }
          limit: ${PAGE_SIZE}
          offset: ${(page - 1) * PAGE_SIZE}
        ) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return {
      total: data.ticket_aggregate.aggregate.count,
      tickets: data.ticket,
    };
  } catch (error) {
    return {
      total: 0,
      tickets: [],
    };
  }
}

const CHAIN_LABEL_COLOR = {
  EVM: "blue",
  ICP: "purple",
  BTC: "yellow",
  unknown: "gray",
  Solana: "purple",
  OSMOSIS: "green",
};

export default function AddrTickets() {
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [tickets, setTickets] = useState<TicketItem[]>([]);
  const [tokenIds, setTokenIds] = useState<string[]>([]);
  const [chainIds, setChainIds] = useState<ChainID[]>([]);
  const params = useParams();
  const address = beautifyAddress(params.address);

  const [searchParams, setSearchParams] = useSearchParams();

  const _page = searchParams.get("page") ?? "1";
  const _chainId = searchParams.get("chainId");
  const _action = searchParams.get("action");
  const _tokenId = searchParams.get("tokenId");
  const _status = searchParams.get("status");
  const [chainId, setChainId] = useState<ChainID | undefined>(
    _chainId as ChainID,
  );
  const [action, setAction] = useState<TicketAction | undefined>(
    _action as TicketAction,
  );
  const [tokenId, setTokenId] = useState<string>(_tokenId ?? "");
  const [status, setStatus] = useState<TicketStatus | undefined>(
    _status as TicketStatus,
  );

  const page = Number(_page) ?? 1;
  useEffect(() => {
    if (address) {
      fetchTicketsByAddress(
        address,
        page,
        chainId,
        action,
        tokenId,
        status,
      ).then((res) => {
        setTickets(res.tickets);
        setCount(res.total);
      });
      const params: Record<string, string | string[]> = {};
      if (chainId) {
        params.chainId = chainId;
      }
      if (action) {
        params.action = action;
      }
      if (tokenId) {
        params.tokenId = tokenId;
      }
      if (status) {
        params.status = status;
      }
      if (page) {
        params.page = String(page);
      }
      setSearchParams(params);
    }
  }, [address, chainId, action, tokenId, status, page]);

  useEffect(() => {
    if (address) {
      fetchTicketsByAddress(address).then((res) => {
        setTickets(res.tickets);
        setTotal(res.total);
        const tokenIds = res.tickets.map((tx) => tx.token);
        setTokenIds(_.uniq(tokenIds));

        const chainIds = res.tickets
          .map((tx) => [tx.src_chain, tx.dst_chain])
          .flat();
        setChainIds(_.uniq(chainIds) as ChainID[]);
      });
    }
  }, [address]);

  const chainLabel = getChainByAddress(address);

  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(address, {
    timeout: 1000,
  });

  const onClearAll = () => {
    setChainId(undefined);
    setAction(undefined);
    setTokenId("");
    setStatus(undefined);
  };

  const isAddress = getKeywordType(address) === "address";

  const { tokens } = useMetadata();
  const activeRunes = tokenIds.map((tokenId) =>
    tokens.find((t) => t.token_id === tokenId),
  );

  return (
    <VStack w="100%" alignItems="flex-start" gap={0}>
      <Helmet>
        <title>Address {address} | Omnity Hub Explorer</title>
      </Helmet>
      <VStack alignItems="flex-start" mb={4} pl={{ base: 4, md: 0 }}>
        <HStack>
          <Text fontSize={16} color="gray.400">
            Address
          </Text>
          {isAddress ? (
            <Badge fontSize={20} colorScheme={CHAIN_LABEL_COLOR[chainLabel]}>
              {chainLabel}
            </Badge>
          ) : (
            <Badge fontSize={20} colorScheme="red">
              Invalid Address
            </Badge>
          )}
        </HStack>
        <HStack gap={4}>
          <Text
            fontSize={24}
            fontWeight={600}
            maxW={{ base: 300, md: 1000 }}
            fontFamily="monospace"
            textAlign="left"
          >
            {address}
          </Text>
          {hasCopied ? (
            <CheckCheck color="green" size={24} />
          ) : (
            <Copy
              color="#999"
              onClick={() => {
                onCopy();
                toast({
                  title: "Address copied",
                  status: "success",
                  duration: 1000,
                });
              }}
              cursor="pointer"
            />
          )}
        </HStack>
      </VStack>

      <Grid
        w="100%"
        templateColumns={{
          base: `repeat(1, 1fr)`,
          md: `repeat(3, 1fr)`,
        }}
        borderWidth={1}
        borderColor="gray.500"
        borderLeftWidth={0}
        borderRightWidth={0}
      >
        <GridItem borderRightWidth={1} borderColor="gray.500">
          <KeyValuePair
            item={{ key: "Tickets", value: total }}
            fontSize={24}
            isNumber
          />
        </GridItem>
        <GridItem borderRightWidth={1} borderColor="gray.500">
          <KeyValuePair
            alignItems="center"
            item={{
              key: "Active on Chains",
              value: (
                <HStack>
                  {chainIds.map((chain_id) => {
                    return (
                      <IChainLogo
                        key={chain_id}
                        chain={chain_id as ChainID}
                        size={28}
                      />
                    );
                  })}
                </HStack>
              ),
            }}
            fontSize={24}
          />
        </GridItem>
        <GridItem>
          <KeyValuePair
            alignItems="flex-end"
            item={{
              key: "Active Runes",
              value: (
                <AvatarGroup size="sm" max={5}>
                  {activeRunes.map((token) => {
                    return (
                      <Avatar
                        key={token?.token_id}
                        src={token?.icon}
                        name={token?.token_id}
                        size="sm"
                      />
                    );
                  })}
                </AvatarGroup>
              ),
            }}
            fontSize={24}
          />
        </GridItem>
      </Grid>

      <Stack
        w="100%"
        mt={8}
        gap={2}
        color="gray.200"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="flex-end"
        flexDir={{ base: "column", md: "row" }}
        pl={{ base: 4, md: 0 }}
      >
        <TicketFilterChainId
          chainId={chainId}
          setChainId={setChainId}
          chainIds={chainIds}
        />
        <TicketFilterTokenId
          tokenId={tokenId}
          setTokenId={setTokenId}
          tokenIds={tokenIds}
        />
        <TicketFilterAction action={action} setAction={setAction} />
        <TicketFilterStatus status={status} setStatus={setStatus} />
        <Text color="blue.500" cursor="pointer" onClick={onClearAll}>
          CLEAR ALL
        </Text>
      </Stack>
      <Divider bg="gray.500" w="100%" h={1} mt={4} />
      <TicketTable items={tickets} />
      <Pagination total={count} page={page} />
    </VStack>
  );
}
