import {
  Avatar,
  Badge,
  HStack,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableCaption,
  Box,
  Link,
  Divider,
  AvatarGroup,
} from "@chakra-ui/react";
import { ChainID } from "@omnity/widget/src/types";
import { useMetadata } from "src/contexts/metadata";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { beautifyTokenId } from "src/utils/format";
import { MD_WIDTH, PROTOCOL_COLORS } from "src/utils/constants";
import { Helmet } from "react-helmet";
import { readableNumber } from "@omnity/widget/src/utils/format";
import useVolume from "src/hooks/useVolume";
import IChainLogo from "src/components/IChainLogo";
import { getAssetProtocol } from "src/utils/chains";

async function fetchTicketCountByToken(token_id: string) {
  try {
    const doc = gql`
      {
        ticket_aggregate(
          where: { token: { _eq: "${token_id}" } }
        ) {
          aggregate {
            count
          }
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return data.ticket_aggregate.aggregate.count;
  } catch (error) {
    return 0;
  }
}

enum TokenProtocol {
  RUNES = "RUNES",
  ICRC = "ICRC",
  BRC20 = "BRC-20",
}

export default function Tokens() {
  const [counts, setCounts] = useState<number[]>([]);
  const { tokens, formatTokenAmount } = useMetadata();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const _asset = searchParams.get("asset");
  const [tp, setTp] = useState(_asset ?? TokenProtocol.RUNES);

  useEffect(() => {
    Promise.all(
      tokens.map(async (t) => {
        return await fetchTicketCountByToken(t.token_id);
      }),
    )
      .then((ticket_counts) => {
        setCounts(ticket_counts);
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens.length]);

  const { runesVolumes } = useVolume();

  return (
    <VStack w="100%" alignItems="flex-start" mt={8} gap={0}>
      <Helmet>
        <title>Rune List | Omnity Hub Explorer</title>
      </Helmet>
      <VStack w="100%" gap={1}>
        <Text fontSize={28}>Tokens List</Text>
        <HStack fontSize={14}>
          <Text color="gray.400">Not listed?</Text>
          <Link
            href="https://bridge.omnity.network/add%20runes"
            target="_blank"
            color="blue.400"
          >
            Add here
          </Link>
        </HStack>
      </VStack>

      <HStack w="100%" mt={4} mb={4} justifyContent="center">
        {Object.values(TokenProtocol).map((protocol) => {
          const isActive = protocol === tp;
          return (
            <Box
              key={protocol}
              bg={isActive ? "gray.600" : ""}
              px={6}
              cursor="pointer"
              onClick={() => {
                setTp(protocol);
                const params: Record<string, string | string[]> = {};
                if (protocol) {
                  params.asset = protocol;
                }
                setSearchParams(params);
              }}
              borderRadius="full"
            >
              <Text fontSize={24} fontWeight={600} color={isActive ? "" : ""}>
                {protocol}
              </Text>
            </Box>
          );
        })}
      </HStack>

      <Divider bg="gray.500" w="100%" h={1} mt={4} />

      <Box w={{ base: window.innerWidth, md: "100%" }} overflowX="scroll">
        <TableContainer w={{ base: "100%", md: MD_WIDTH }}>
          <Table size="lg">
            <TableCaption>
              {tokens.length === 0 ? "There is no tokens" : ""}
            </TableCaption>
            <Thead>
              <Tr>
                <Th>Token</Th>
                <Th textAlign="right">TVL</Th>
                <Th textAlign="right">Tickets</Th>
                <Th textAlign="right">Amount on L2</Th>
                <Th>Chains</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tokens
                .map((t, idx) => {
                  const volume =
                    runesVolumes.find((r) => r.token_id === t.token_id)
                      ?.volume ?? 0;
                  return {
                    ...t,
                    ticket_count: counts[idx] ?? 0,
                    volume,
                  };
                })
                .filter((t) => {
                  if (tp === TokenProtocol.RUNES) {
                    return t.token_id.startsWith("Bitcoin-runes");
                  }
                  if (tp === TokenProtocol.ICRC) {
                    return t.token_id.startsWith("sICP");
                  }
                  if (tp === TokenProtocol.BRC20) {
                    return t.token_id.startsWith("Bitcoinbrc20-brc20");
                  }
                })
                .sort((a, b) => b.volume - a.volume)
                .map((t) => {
                  const _volumeAmount = formatTokenAmount?.(
                    t.volumeAmount?.toString() ?? "0",
                    t.token_id,
                    false,
                  );

                  const balance = _volumeAmount?.balance ?? 0;
                  const protocol = getAssetProtocol({ token: t.token_id });
                  return (
                    <Tr
                      key={t.token_id}
                      cursor="pointer"
                      _hover={{ bg: "gray.700" }}
                      onClick={() =>
                        navigate(`/token/${beautifyTokenId(t.token_id)}`)
                      }
                    >
                      <Td>
                        <HStack>
                          <Avatar
                            size="md"
                            src={t.icon}
                            name={t.symbol}
                            borderWidth={1}
                            borderColor="gray.500"
                          />
                          <VStack gap={0} alignItems="flex-start">
                            <Text fontWeight={600} fontSize={18}>
                              {t?.name}
                            </Text>
                            <HStack>
                              <Badge
                                colorScheme={
                                  PROTOCOL_COLORS[protocol ?? "tokens"] ??
                                  "gray"
                                }
                                fontSize={12}
                                size="sm"
                              >
                                {protocol}
                              </Badge>
                              <Badge colorScheme="blue" fontSize={12} size="sm">
                                {t.metadata.rune_id}
                              </Badge>
                            </HStack>
                          </VStack>
                        </HStack>
                      </Td>
                      <Td
                        fontSize={18}
                        fontWeight={600}
                        color="gray.200"
                        textAlign="right"
                        className="number"
                      >
                        ${readableNumber(Math.ceil(t.volume), 0)}
                      </Td>
                      <Td color="gray.400" textAlign="right" className="number">
                        {t.ticket_count}
                      </Td>
                      <Td color="gray.400" textAlign="right" className="number">
                        {readableNumber(
                          balance,
                          Number(t.price ?? 0) > 1000 ? 4 : 0,
                        )}
                      </Td>
                      <Td>
                        <AvatarGroup size="sm" max={5}>
                          {t.dst_chains.map((chain_id) => {
                            return (
                              <Avatar
                                icon={
                                  <IChainLogo
                                    key={chain_id}
                                    chain={chain_id as ChainID}
                                    size={28}
                                  />
                                }
                                background="transparent"
                                key={chain_id}
                              />
                            );
                          })}
                        </AvatarGroup>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </VStack>
  );
}
