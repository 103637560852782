import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  chakra,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
  HStack,
  Text,
} from "@chakra-ui/react";
import ServiceFactory from "@omnity/widget/src/services/Factory";
import { TableIcon, ArrowUpRight } from "lucide-react";
import { MD_WIDTH } from "src/utils/constants";
import { beautifyAddress, beautifyTokenId } from "src/utils/format";
import IChainLogo from "./IChainLogo";
import { TokenMeta } from "src/types";
import { getChainName } from "src/utils/chains";

const TableIconed = chakra(TableIcon);
const ArrowRightUpIcon = chakra(ArrowUpRight);

export default function RuneContractIdTable({ rune }: { rune?: TokenMeta }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  if (!rune) {
    return null;
  }
  return (
    <>
      <TableIconed
        size={26}
        color="blue.400"
        cursor="pointer"
        onClick={onOpen}
      />
      <Modal isCentered size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="#000433">
          <ModalHeader>
            <Text display="inline" color="blue.400">
              {beautifyTokenId(rune.token_id)}
            </Text>{" "}
            Contracts
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer w={{ base: "100%", md: MD_WIDTH }}>
              <Table size="md">
                <Thead>
                  <Tr>
                    <Th>Chain</Th>
                    <Th>Contract Address</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {rune.token_ledger_id_on_chains.map((item) => {
                    const url = ServiceFactory.getTokenLink({
                      chain_id: item.chain_id,
                      id: beautifyAddress(item.contract_id),
                    });
                    return (
                      <Tr
                        key={item.chain_id}
                        borderBottomWidth={1}
                        borderBottomColor="yellow"
                      >
                        <Td>
                          <HStack>
                            <IChainLogo chain={item.chain_id} size={24} />
                            <Text fontWeight={600}>
                              {getChainName(item.chain_id)}
                            </Text>
                          </HStack>
                        </Td>
                        <Td>
                          <Link color="blue.400" href={url} target="_blank">
                            <HStack>
                              <Text>{beautifyAddress(item.contract_id)}</Text>
                              <ArrowRightUpIcon size={20} color="blue.400" />
                            </HStack>
                          </Link>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
