import { chakra, HStack, Link, Spinner, Text } from "@chakra-ui/react";
import { ChainID } from "@omnity/widget/src/types";
import ServiceFactory from "@omnity/widget/src/services/Factory";
import { ArrowUpRight } from "lucide-react";
import { TxHashType } from "src/types";
import IChainLogo from "./IChainLogo";
import { MD_WIDTH } from "src/utils/constants";

const ArrowUpRightIcon = chakra(ArrowUpRight);

export default function HashLink({
  hash,
  chain,
  ellipsis = true,
  fontSize = 16,
  isFinalized = false,
  confirmation,
}: {
  hash?: string;
  chain: string;
  ellipsis?: boolean;
  fontSize?: number;
  isFinalized?: boolean;
  confirmation?: { txHashType: TxHashType; confirmations: number };
}) {
  let _hash = hash?.trim();
  let url = ServiceFactory.getTicketIdLink({
    src_chain: chain as ChainID,
    ticket_id: _hash,
  });
  if (confirmation) {
    if (confirmation.confirmations === 0) {
      url = `https://mempool.space/tx/${_hash}`;
    } else {
      url = `https://www.runescan.net/txs/${_hash}`;
    }
  }
  if (!_hash) {
    _hash = "-";
  } else {
    _hash = ellipsis ? _hash.slice(0, 8) + "..." : _hash;
  }

  const maxW = window.outerWidth - 120;

  return (
    <Link href={url} target={url !== "#" ? "_blank" : ""}>
      <HStack>
        <IChainLogo chain={chain as ChainID} size={32} />
        {url !== "#" ? (
          <Text
            fontSize={fontSize}
            color={url === "#" ? "" : "blue.400"}
            fontFamily="monospace"
            textAlign="left"
            maxW={{ base: maxW, md: MD_WIDTH - 400 }}
            isTruncated
          >
            {_hash}
          </Text>
        ) : _hash ? (
          <Text
            fontSize={fontSize}
            fontFamily="monospace"
            maxW={{ base: maxW, md: MD_WIDTH - 400 }}
            isTruncated
          >
            {_hash}
          </Text>
        ) : isFinalized ? (
          <Text fontSize={fontSize} fontFamily="monospace">
            -
          </Text>
        ) : (
          <HStack>
            <Spinner />
            <Text color="gray.500" fontSize={18}>
              Waiting
            </Text>
          </HStack>
        )}

        {url !== "#" && <ArrowUpRightIcon size={24} color="blue.400" />}
      </HStack>
    </Link>
  );
}
