import "./global.css";
import { Outlet } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import { Stack, VStack } from "@chakra-ui/react";
import { MD_WIDTH } from "./utils/constants";

export default function Layout() {
  const mdWidth =
    window.outerWidth > MD_WIDTH
      ? MD_WIDTH
      : window.outerWidth < MD_WIDTH * 0.8
        ? "80%"
        : "90%";

  return (
    <Stack bg="#000433" color="white">
      <Header />
      <VStack
        w={{ base: "100%", md: mdWidth }}
        minW={{ base: "100%", md: mdWidth }}
        alignItems="center"
        gap={8}
        margin="0 auto"
      >
        <Outlet />
      </VStack>
      <Footer />
    </Stack>
  );
}
