import {
  Avatar,
  AvatarGroup,
  chakra,
  HStack,
  Input,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMetadata } from "src/contexts/metadata";
import { formatUnits, readableNumber } from "@omnity/widget/src/utils/format";
import { Helmet } from "react-helmet";
import LatestTickets from "src/components/LatestTickets";
import ChainStats from "src/components/ChainStats";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getKeywordType } from "src/utils/format";
import { ChevronRight } from "lucide-react";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import useVolume from "src/hooks/useVolume";
import { atom, useAtom } from "jotai";

const ChevronRightIcon = chakra(ChevronRight);

interface TokenVolumeItem {
  historical_volume: string;
  token_id: string;
}

async function fetchTokenVolumes(): Promise<TokenVolumeItem[]> {
  try {
    const doc = gql`
      {
        token_volume {
          historical_volume
          token_id
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return data.token_volume;
  } catch (error) {
    return [];
  }
}

const stableAtom = atom<TokenVolumeItem[]>([]);

export default function Home() {
  const { tokens, ticket_count, priceFetched } = useMetadata();
  const [keyword, setKeyword] = useState("");
  const [tokenVolumes, setTokenVolumes] = useAtom(stableAtom);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTokenVolumes().then((res) => {
      setTokenVolumes(res);
    });
  }, []);

  const totalVolume = useMemo(() => {
    if (tokenVolumes.length > 0 && priceFetched) {
      let volume = 0;
      tokenVolumes.forEach((t) => {
        const token = tokens.find((tk) => tk.token_id === t.token_id);
        if (token) {
          volume +=
            Number(formatUnits(BigInt(t.historical_volume), token.decimals)) *
            Number(token.price ?? 0);
        }
      });
      return volume;
    }
    return undefined;
  }, [tokenVolumes.length, tokens.length, priceFetched]);

  const { totalVolume: totalVolumeLocked } = useVolume();

  return (
    <VStack w="100%" gap={4}>
      <Helmet>
        <title>Home | Omnity Hub Explorer</title>
      </Helmet>

      <VStack w={{ base: "100%", md: 600 }} px={{ base: 4, md: 0 }} py={16}>
        <Text fontSize={{ base: 18, md: 36 }} mb={4} fontWeight={600}>
          Omnity Hub
        </Text>
        <Input
          placeholder="Search Ticket/Address"
          w="100%"
          size="lg"
          borderRadius="full"
          borderColor="gray.400"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const type = getKeywordType(keyword.trim());
              if (type === "address") {
                navigate(`/address/${keyword.trim()}`);
              } else {
                navigate(`/ticket/${keyword.trim()}`);
              }
              setKeyword("");
            }
          }}
        />
      </VStack>

      <Stack
        w="100%"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={{ base: "center", md: "space-around" }}
        flexDir={{ base: "column", md: "row" }}
        px={{ base: 8, md: 0 }}
        gap={4}
      >
        <VStack
          gap={0}
          py={8}
          bg="rgba(23, 28, 74, 1)"
          w={{ base: "100%", md: "28%" }}
        >
          <Text color="gray.400">Total Tickets</Text>
          <Text fontSize={48} className="number" lineHeight={1}>
            {readableNumber(ticket_count)}
          </Text>
        </VStack>
        <VStack
          gap={0}
          py={8}
          bg="rgba(23, 28, 74, 1)"
          w={{ base: "100%", md: "42%" }}
        >
          <Text color="gray.400">Total Volume</Text>
          <Text fontSize={48} className="number" lineHeight={1}>
            {totalVolume === undefined ? (
              <Skeleton w={240} h="48px" />
            ) : (
              `$${readableNumber(Math.ceil(totalVolume), 0)}`
            )}
          </Text>
        </VStack>
        <VStack
          gap={0}
          py={8}
          bg="rgba(23, 28, 74, 1)"
          w={{ base: "100%", md: "28%" }}
        >
          <Text color="gray.400">TVL</Text>
          <Text fontSize={48} className="number" lineHeight={1}>
            {totalVolumeLocked === 0 ? (
              <Skeleton w={240} h="48px" />
            ) : (
              `$${readableNumber(Math.ceil(totalVolumeLocked), 0)}`
            )}
          </Text>
        </VStack>
      </Stack>

      <Stack
        w="100%"
        gap={4}
        alignItems="flex-start"
        justifyContent="center"
        flexDir={{ base: "column", md: "row" }}
      >
        <VStack w={{ base: "100%", md: "35%" }} gap={4}>
          <ChainStats />

          <HStack
            w="100%"
            justifyContent="center"
            gap={4}
            bg="rgba(23, 28, 74, 1)"
          >
            <AvatarGroup size="md" max={5} py={7}>
              {tokens.map((t) => {
                return (
                  <Avatar key={t.token_id} name={t.token_id} src={t.icon} />
                );
              })}
            </AvatarGroup>
            <Link to="/tokens">
              <HStack gap={1} fontWeight={400} color="blue.400">
                <Text>ALL</Text>
                <ChevronRightIcon size={18} />
              </HStack>
            </Link>
          </HStack>
        </VStack>
        <LatestTickets />
      </Stack>
    </VStack>
  );
}
