import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Text,
  VStack,
  chakra,
  Skeleton,
} from "@chakra-ui/react";
import { ChainID } from "@omnity/widget/src/types";
import { useMetadata } from "src/contexts/metadata";
import { getChainName } from "src/utils/chains";
import { useEffect, useState } from "react";
import _ from "lodash";
import { ChevronRight } from "lucide-react";
import { readableNumber } from "@omnity/widget/src/utils/format";
import useVolume from "src/hooks/useVolume";
import { Link, useNavigate } from "react-router-dom";
import IChainLogo from "./IChainLogo";

const ChevronRightIcon = chakra(ChevronRight);

export default function ChainStats() {
  const { chains } = useMetadata();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { chainVolumes, totalVolume } = useVolume();

  useEffect(() => {
    setIsLoading(true);
    if (totalVolume === 0) return;
    setIsLoading(false);
  }, [totalVolume]);

  return (
    <VStack
      alignItems="flex-start"
      p={4}
      py={8}
      pr={{ base: 4, md: 8 }}
      gap={2}
      w={{ base: window.innerWidth, md: "100%" }}
      overflowX="scroll"
      bg="rgba(23, 28, 74, 1)"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold">
          Chain Stats
        </Text>
        <Link to="/chains">
          <HStack gap={1} fontWeight={400} color="blue.400">
            <Text>MORE</Text>
            <ChevronRightIcon size={18} />
          </HStack>
        </Link>
      </HStack>
      <TableContainer w="100%" minW={{ base: "100%", md: 300 }}>
        <Table size="md">
          <Thead>
            <Tr>
              <Th>Chain</Th>
              <Th textAlign="right">TVL</Th>
            </Tr>
          </Thead>
          <Tbody>
            {chains
              .filter((chain) => chain.chain_id !== ChainID.Bitcoin)
              .map((chain) => {
                const volume =
                  chainVolumes?.find((c) => c.chain_id === chain.chain_id)
                    ?.volume ?? 0;
                return {
                  ...chain,
                  volume,
                };
              })
              .sort((a, b) => b.volume - a.volume)
              .slice(0, 8)
              .map((chain) => {
                const chain_id = chain.chain_id as ChainID;
                const chain_name = getChainName(chain_id);
                return (
                  <Tr
                    key={chain_id}
                    cursor="pointer"
                    _hover={{ bg: "gray.700" }}
                    onClick={() => {
                      navigate(`/chain/${chain_name}`);
                    }}
                  >
                    <Td>
                      <HStack>
                        <IChainLogo chain={chain_id} size={28} />
                        <Text fontWeight={600} fontSize={18} color="gray.200">
                          {chain_name}
                        </Text>
                      </HStack>
                    </Td>
                    <Td
                      textAlign="right"
                      fontWeight={600}
                      color="gray.300"
                      className="number"
                    >
                      <HStack justifyContent="flex-end">
                        {isLoading ? (
                          <Skeleton w={20} h={18} />
                        ) : (
                          <Text>
                            ${readableNumber(Math.ceil(chain.volume), 0)}
                          </Text>
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
}
