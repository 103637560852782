import { ChainID, ChainName } from "@omnity/widget/src/types";
import { CHAIN_LIST } from "@omnity/widget/src/utils/constants";
import { getChainIdFromName } from "@omnity/widget/src/utils/chains";
import { Circle } from "@chakra-ui/react";

export default function IChainLogo({
  size = 32,
  chain,
}: {
  size?: number;
  chain: ChainID | ChainName;
}) {
  if (CHAIN_LIST[chain]) {
    const Icon = CHAIN_LIST[chain].icon;
    return (
      <Circle size={`${size}px`} overflow="hidden" title={chain}>
        <Icon size={size} />
      </Circle>
    );
  } else {
    const chainIds = getChainIdFromName(chain as string);
    if (chainIds[0]) {
      return <IChainLogo size={size} chain={chainIds[0]} />;
    }
  }
  return null;
}
