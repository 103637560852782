import { TicketAction } from "../types";

export function formatActionQuery(action?: TicketAction) {
  if (!action) {
    return "";
  }

  if (
    action === TicketAction.Redeem ||
    action === TicketAction.RedeemIcpChainKeyAssets
  ) {
    return `action: {_in: ["${TicketAction.Redeem}", "${TicketAction.RedeemIcpChainKeyAssets}"]}`;
  }
  return `action: { _eq: "${action}" }`;
}
