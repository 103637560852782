import { getICPSwapIndexService } from "src/candids";
import { TokenMeta } from "src/types";

async function fetchRunePrice(rune_id?: string) {
  try {
    if (!rune_id) {
      return "0";
    }
    const res = await fetch(
      `https://api.runego.com/api/v1/rune/${rune_id}`,
    ).then((res) => res.json());
    return res.data.runeInfo.floorPriceUsd;
  } catch (error) {
    return "0";
  }
}

export async function fetchTokenPrice(tokens: TokenMeta[]) {
  try {
    let fetchedRunesTokens = tokens.filter((token) =>
      token.token_id.startsWith("Bitcoin-runes-"),
    );
    try {
      fetchedRunesTokens = await Promise.all(
        fetchedRunesTokens.map((rune) => fetchRunePrice(rune.metadata.rune_id)),
      ).then((results) => {
        return fetchedRunesTokens.map((rune, index) => ({
          ...rune,
          price: results[index],
        }));
      });
    } catch (error) {}

    let fetchedIcpTokens = tokens
      .filter((token) => token.token_id.startsWith("sICP-"))
      .map((t) => {
        if (t.token_id === "sICP-native-ICP") {
          return {
            ...t,
            metadata: { ledger_id: "ryjl3-tyaaa-aaaaa-aaaba-cai" },
          };
        }
        return t;
      });
    try {
      const nodeIndex = await getICPSwapIndexService();
      const allICPTokens = await nodeIndex.getAllTokens();

      fetchedIcpTokens = fetchedIcpTokens.map((icpToken) => {
        const t = allICPTokens.find(
          (t: any) => t.address === icpToken.metadata.ledger_id,
        );
        if (t) {
          return { ...icpToken, price: `${t.priceUSD}` };
        }
        return icpToken;
      });
    } catch (error) {}

    let fetchedBrc20Tokens = tokens.filter((token) =>
      token.token_id.startsWith("Bitcoinbrc20-brc20"),
    );
    try {
      const brc20TokenPrices = await fetch(
        `https://www.brc-20.io/coinranking/v2/coins?offset=0&orderBy=marketCap&limit=100&orderDirection=desc&referenceCurrencyUuid=yhjMzLPhuIDl&timePeriod=24h&search=&tags[]=brc-20`,
      ).then((res) => res.json());
      fetchedBrc20Tokens = fetchedBrc20Tokens.map((brc20Token) => {
        const t = brc20TokenPrices.data.coins.find(
          (t: any) => t.symbol === brc20Token.symbol,
        );
        if (t) {
          return { ...brc20Token, price: t.price };
        }
        return brc20Token;
      });
    } catch (error) {}

    return [...fetchedRunesTokens, ...fetchedIcpTokens, ...fetchedBrc20Tokens];
  } catch (error) {
    return tokens;
  }
}
